import { GraphSeriesConfig } from "@/types";

export const MAX_SERIES = 8;
export const DISABLED_SERIES_COLOR = "#aaaaaa";
export const DEFAULT_VOLTAGE = 120;
export const DEFAULT_POWER_FACTOR = 1;
export const DEFAULT_STALE_DATA_DURATION = 75;

export const DEFAULT_GRAPH_SERIES_CONFIG: GraphSeriesConfig = {
  hideValueUnit: false,
  comparable: false,
  aggregation: "MAXIMUM",
  stepGraph: false,
  fitBounds: false
};

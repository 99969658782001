import { mergeAssetConfig } from "@/config/asset-config-reader";
import baseAssetConfig from "@/config/base-asset";

const config = mergeAssetConfig(baseAssetConfig, {
  categoryProperties: {
    battery: { property: "battery", unit: "pct" },
    rssi: { property: "rssi", unit: "db" }
  }
});

export default config;

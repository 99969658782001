import { mergeAssetConfig } from "@/config/asset-config-reader";
import baseDeviceConfig from "@/config/base-device";
import { DEFAULT_POWER_FACTOR, DEFAULT_VOLTAGE } from "@/config/constants";

const config = mergeAssetConfig(baseDeviceConfig, {
  i18nNamespace: "energy_pro",
  components: {
    AssetDashboardTab: () => import("@/energy-pro/components/DeviceDashboardTab.vue"),
    AssetDialogProperties: () => import("@/energy-pro/components/AssetDialogProperties.vue")
  },
  categoryProperties: {
    average_current: { property: "average_milliampere", unit: "amps" },
    average_power: { property: "average_milliampere", unit: "kw" }
  },
  properties: {
    average_milliampere: {
      format: "decimal2dd",
      unit: "milliamps",
      aggregation: "MAXIMUM",
      comparable: true,
      tickAmount: 5
    },
    average_energy: {
      dataType: "number",
      format: "decimal2d",
      unit: "amps",
      stepGraph: true
    },
    voltage: {
      dataType: "number",
      category: "settings",
      format: "integer",
      unit: "volts",
      defaultValue: DEFAULT_VOLTAGE,
      fieldConfig: {
        rules: {
          required: true,
          between: { min: 0, max: 600 },
          integer: true
        }
      }
    },
    power_factor: {
      dataType: "number",
      category: "settings",
      format: "decimal2dd",
      defaultValue: DEFAULT_POWER_FACTOR,
      fieldConfig: {
        rules: {
          required: true,
          between: { min: 0, max: 1 }
        }
      }
    }
  }
});

export default config;

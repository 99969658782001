import { mergeAssetConfig } from "@/config/asset-config-reader";
import baseDeviceConfig from "@/config/base-device";
import { PropertyConfig } from "@/types";

const TEMP_PROPERTY_CONFIG: Partial<PropertyConfig> = {
  unit: "degrees_c",
  unitSelectorFn: () => "degrees_f",
  altUnits: {
    degrees_f: {
      min: -4,
      max: 140,
      format: "decimal1dd",
      graphFormat: "decimal1dd",
      aggregation: "AVERAGE",
      fitBounds: true
    }
  },
  comparable: true
};

const config = mergeAssetConfig(baseDeviceConfig, {
  i18nNamespace: "iaq",
  components: {
    AssetDashboardTab: {
      component: () => import("@/iaq/components/DeviceDashboardTabTemp100.vue")
    },
    AssetDialogProperties: {
      component: () => import("@/components/AssetDialogProperties.vue"),
      props: {
        itemProperties: ["temp_c", "temp_c_chan2"]
      }
    }
  },
  categoryProperties: {
    temperature: { property: "temp_c" }
  },
  properties: {
    temp_c: TEMP_PROPERTY_CONFIG,
    temp_c_chan2: TEMP_PROPERTY_CONFIG
  }
});

export default config;

import { computed, ComputedRef, unref } from "vue";
import { dropRight, tail, zip } from "lodash";
import Highcharts from "highcharts";
import { MaybeRef, ThresholdPair } from "@/types";

const MIN_THRESHOLD = -3333000;
const MAX_THRESHOLD = 3333000;

const RANGE_STYLES: Record<number, Record<"plot" | "navigator", any>> = {
  [-2]: {
    plot: {
      color: "rgba(235, 87, 87, 0.3)",
      label: {
        text: "Critical",
        style: {
          color: "red"
        }
      }
    },
    navigator: {
      color: "rgb(235, 87, 87)"
    }
  },
  [-1]: {
    plot: {
      color: "rgba(250, 180, 44, 0.3)",
      label: {
        text: "Warning",
        style: {
          color: "orange"
        }
      }
    },
    navigator: {
      color: "rgb(250, 180, 44)"
    }
  },
  0: {
    plot: {
      color: "rgba(60, 179, 113, 0.3)",
      label: {
        text: "Okay",
        style: {
          color: "green"
        }
      }
    },
    navigator: {
      color: "#3cb371"
    }
  },
  1: {
    plot: {
      color: "rgba(250, 180, 44, 0.3)",
      label: {
        text: "Warning",
        style: {
          color: "orange"
        }
      }
    },
    navigator: {
      color: "rgb(250, 180, 44)"
    }
  },
  2: {
    plot: {
      color: "rgba(235, 87, 87, 0.3)",
      label: {
        text: "Critical",
        style: {
          color: "red"
        }
      }
    },
    navigator: {
      color: "rgb(235, 87, 87)"
    }
  }
};

interface ThresholdRange {
  violationLevel: number;
  rangeStart: number | undefined;
  rangeEnd: number | undefined;
}

export interface UseThresholdRangesResult {
  thresholdRanges: ComputedRef<ThresholdRange[]>;
  navigatorZones: ComputedRef<Highcharts.SeriesZonesOptionsObject[]>;
  plotBands: ComputedRef<Highcharts.YAxisPlotBandsOptions[]>;
}

export default function useThresholdRanges(
  thresholds: MaybeRef<ThresholdPair[] | undefined>
): UseThresholdRangesResult {
  const thresholdRanges = computed(() => {
    const thresholdsValue = unref(thresholds);
    if (!thresholdsValue || thresholdsValue.length === 0) return [];

    const thresholdsWithEnds = [null, ...thresholdsValue, null];
    const thresholdChunks = zip(dropRight(thresholdsWithEnds, 1), tail(thresholdsWithEnds));

    const ranges: ThresholdRange[] = thresholdChunks.map(chunk => {
      const [startThreshold, endThreshold]: (ThresholdPair | null | undefined)[] = chunk;
      const startLevel = startThreshold?.violationLevel;
      const endLevel = endThreshold?.violationLevel;

      let violationLevel: number;

      if (startLevel === undefined) {
        const endLevelNumber = endLevel as number;
        violationLevel = endLevelNumber > 0 ? 0 : endLevelNumber;
      } else if (endLevel === undefined) {
        const startLevelNumber = startLevel as number;
        violationLevel = startLevelNumber < 0 ? 0 : startLevelNumber;
      } else {
        const startLevelNumber = startLevel as number;
        const endLevelNumber = endLevel as number;
        if (startLevelNumber < 0) {
          violationLevel = endLevelNumber < 0 ? endLevelNumber : 0;
        } else {
          violationLevel = startLevelNumber;
        }
      }

      return { violationLevel, rangeStart: startThreshold?.compareValue, rangeEnd: endThreshold?.compareValue };
    });

    return ranges;
  });

  const navigatorZones = computed<Highcharts.SeriesZonesOptionsObject[]>(() => {
    return thresholdRanges.value.map(range => ({
      value: range.rangeEnd ?? MAX_THRESHOLD,
      ...RANGE_STYLES[range.violationLevel].navigator
    }));
  });

  const plotBands = computed<Highcharts.YAxisPlotBandsOptions[]>(() => {
    return thresholdRanges.value.map(range => ({
      from: range.rangeStart ?? MIN_THRESHOLD,
      to: range.rangeEnd ?? MAX_THRESHOLD,
      ...RANGE_STYLES[range.violationLevel].plot
    }));
  });

  return {
    thresholdRanges,
    navigatorZones,
    plotBands
  };
}

import { computed, ComputedRef, unref } from "vue";
import { isNil, range, toPairs } from "lodash";
import Highcharts from "highcharts";
import { fitBoundsToThresholds, formatNumber, isCategoryFormat } from "@/utils/number";
import { DISABLED_SERIES_COLOR, MAX_SERIES } from "@/config/constants";
import useThresholdRanges from "@/composables/use-threshold-ranges";
import { ChartSeriesDetails, MaybeRef, ChartSeriesGroup, TimeSeries, GraphDataType, PointTimeSeries } from "@/types";
import i18n from "@/plugins/i18n";

export interface UseSeriesOptionsParams {
  group: MaybeRef<ChartSeriesGroup>;
}

export interface UseSeriesOptionsResult {
  isComparing: ComputedRef<boolean>;
  yAxis: ComputedRef<Highcharts.YAxisOptions>;
  mainSeriesOptions: ComputedRef<Highcharts.SeriesOptionsType[]>;
  navigatorSeriesOptions: ComputedRef<Highcharts.NavigatorSeriesOptions[]>;
  height: ComputedRef<number | undefined>;
}

export function useSeriesOptions({ group }: UseSeriesOptionsParams): UseSeriesOptionsResult {
  const { navigatorZones, plotBands } = useThresholdRanges(unref(group).thresholds);

  const isComparing = computed(() => {
    return unref(group).seriesDetails.length > 1;
  });

  const isShowingSingleEntity = computed(() => {
    const [firstSeries, ...otherSeries] = unref(group).seriesDetails;
    return otherSeries.length === 0 || otherSeries.every(s => s.entityName === firstSeries.entityName);
  });

  const isShowingSingleProperty = computed(() => {
    const [firstSeries, ...otherSeries] = unref(group).seriesDetails;
    return otherSeries.length === 0 || otherSeries.every(s => s.propertyName === firstSeries.propertyName);
  });

  const hideValueUnit = computed(() => {
    return isCategory.value ? true : unref(group).graphOptions?.hideValueUnit === true;
  });

  const stepGraph = computed(() => {
    return isCategory.value ? true : unref(group).graphOptions?.stepGraph === true;
  });

  const isCategory = computed(() => {
    return isCategoryFormat(unref(group).graphOptions?.graphFormat);
  });

  const unitStr = computed(() => {
    const unit = unref(group).selectedUnit;
    if (!unit) return "";
    return i18n.t(`units.${unit}`).toString();
  });

  const yBounds = computed(() => {
    let bounds: { softMin?: number; softMax?: number };
    const { fitBounds, min, max } = unref(group).graphOptions ?? {};

    if (fitBounds && !isNil(min) && !isNil(max)) {
      const [softMin, softMax] = fitBoundsToThresholds(min, max, unref(group).thresholds);
      bounds = {
        softMin,
        softMax
      };
    } else {
      bounds = {
        softMin: min,
        softMax: max
      };
    }

    return bounds;
  });

  const yAxis = computed(() => {
    const groupValue = unref(group);
    if (groupValue.graphDataType === "number") {
      return numberYAxis(groupValue);
    } else {
      return textYAxis(groupValue);
    }
  });

  function numberYAxis(group: ChartSeriesGroup): Highcharts.YAxisOptions {
    const baseOptions: Highcharts.YAxisOptions = {
      id: unref(group).groupId,
      title: {
        text: unitStr.value
      },
      plotBands: plotBands.value,
      offset: 21
    };
    let yAxisOptions: Highcharts.YAxisOptions;
    if (isCategoryFormat(unref(group).graphOptions?.graphFormat)) {
      yAxisOptions = {
        ...baseOptions,
        tickPositions: [0, 1],
        labels: {
          formatter: formatYAxisLabel
        }
      };
    } else {
      yAxisOptions = {
        ...baseOptions,
        ...yBounds.value,
        tickAmount: unref(group).graphOptions?.tickAmount,
        tickInterval: unref(group).graphOptions?.tickInterval,
        startOnTick: false,
        endOnTick: false
      };
    }
    return yAxisOptions;
  }

  function textYAxis(group: ChartSeriesGroup): Highcharts.YAxisOptions {
    return {
      id: unref(group).groupId,
      title: {
        text: group.seriesDetails[0].propertyName,
        rotation: 0
      },
      labels: {
        enabled: false
      },
      min: 0,
      max: 100,
      offset: 21,
      gridLineWidth: 0,
      tickAmount: 0
    };
  }

  const mainSeriesOptions = computed<Highcharts.SeriesOptionsType[]>(() => {
    const realSeries = unref(group).seriesDetails.flatMap(s => seriesOptions(s));
    const placeholderCount = MAX_SERIES - realSeries.length;
    const placeholderSeries = range(placeholderCount).map(i => placeholderSeriesOptions(i));

    return [...realSeries, ...placeholderSeries];
  });

  const navigatorSeriesOptions = computed<Highcharts.NavigatorSeriesOptions[]>(() => {
    return unref(group).seriesDetails.flatMap(series => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return seriesPairs(series).map(([_, data]) => {
        return {
          type: "line",
          step: stepGraph.value ? "right" : undefined,
          data: transformPoints(unref(group).graphDataType, data),
          zones: navigatorZones.value,
          animation: {
            duration: 0
          },
          dataGrouping: {
            enabled: false
          }
        };
      });
    });
  });

  const groupHeight = computed<number | undefined>(() => {
    const groupValue = unref(group);
    if (groupValue.graphDataType === "text") return 5;
    return undefined;
  });

  function formatYAxisLabel(context: Highcharts.AxisLabelsFormatterContextObject): string {
    const value = typeof context.value === "number" ? context.value : parseInt(context.value);
    return formatNumber(value, { format: unref(group).graphOptions?.graphFormat }) ?? "";
  }

  function seriesOptions(series: ChartSeriesDetails): Highcharts.SeriesOptionsType[] {
    const groupValue = unref(group);
    if (groupValue.graphDataType === "number") {
      return numberSeriesOptions(series);
    } else {
      return textSeriesOptions(series);
    }
  }

  function numberSeriesOptions(series: ChartSeriesDetails): Highcharts.SeriesOptionsType[] {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return seriesPairs(series).map(([_, data]) => {
      const noData = !series.loading && data.length === 0;
      return {
        name: seriesName(series, noData),
        type: "line",
        yAxis: unref(group).groupId,
        showInNavigator: false,
        showInLegend: true,
        // If the series has no data, change the color of the symbol in the legend
        color: noData ? DISABLED_SERIES_COLOR : undefined,
        step: stepGraph.value ? "right" : undefined,
        data: transformPoints(unref(group).graphDataType, data),
        dataGrouping: {
          enabled: false
        },
        animation: {
          duration: 0
        }
        // tooltip: {
        //   headerFormat: "",
        //   pointFormatter: function () {
        //     const seriesName = isComparing.value ? `<b>${this.series.name}</b><br>` : "";
        //     const formattedDate = Highcharts.dateFormat("%Y-%m-%d %I:%M:%S %p", this.x);
        //     const value = this.y;
        //     const formattedValue = formatNumber(value, { format: unref(group).graphOptions?.graphFormat });
        //     const suffix = hideValueUnit.value ? "" : `${unitStr.value}`;
        //     return `${formattedDate}<br>${seriesName}<b>${formattedValue}${suffix}</b>`;
        //   }
        // }
      };
    });
  }

  function textSeriesOptions(series: ChartSeriesDetails): Highcharts.SeriesOptionsType[] {
    const pointConfig = unref(group).graphOptions?.graphPointConfig;
    if (!pointConfig) return [];

    const options: Highcharts.SeriesOptionsType[] = [];
    for (const [value, data] of seriesPairs(series)) {
      if (!pointConfig[value]) continue;
      options.push({
        name: value,
        type: "area",
        yAxis: unref(group).groupId,
        showInNavigator: false,
        showInLegend: false,
        step: "left",
        color: pointConfig[value].color,
        borderColor: pointConfig[value].color,
        borderWidth: 1,
        lineWidth: 0,
        turboThreshold: 0,
        data: transformPoints(unref(group).graphDataType, data),
        marker: {
          enabled: false
        },
        states: {
          inactive: {
            enabled: false
          }
        },
        dataGrouping: {
          enabled: false
        },
        animation: {
          duration: 0
        }
        // tooltip: {
        //   headerFormat: "",
        //   pointFormatter: function () {
        //     const seriesName = `<b>${this.series.name}</b><br>`;
        //     const formattedDate = Highcharts.dateFormat("%Y-%m-%d %I:%M:%S %p", this.x);
        //     return `${formattedDate}<br>${seriesName}`;
        //   }
        // }
      });
    }

    return options;
  }

  function seriesName(series: ChartSeriesDetails, noData: boolean): string {
    let name = series.entityName;

    if (isComparing.value) {
      if (isShowingSingleEntity.value) {
        name = series.propertyName;
      } else {
        name = isShowingSingleProperty.value ? series.entityName : `${series.entityName}<br/>${series.propertyName}`;
      }
    }

    const noDataStr = noData ? i18n.t("general.no_data_qualifier") : "";
    return `${name} ${noDataStr}`;
  }

  function placeholderSeriesOptions(index: number): Highcharts.SeriesOptionsType {
    const options: Highcharts.SeriesOptionsType = {
      name: `placeholder ${index}`,
      type: "line",
      showInNavigator: false,
      showInLegend: false
    };

    return options;
  }

  return {
    isComparing,
    yAxis,
    mainSeriesOptions,
    navigatorSeriesOptions,
    height: groupHeight
  };
}

function seriesPairs(series: ChartSeriesDetails): [string, TimeSeries][] {
  return toPairs(series.seriesConversionFn(series.series));
}

function transformPoints(type: GraphDataType, data: TimeSeries): TimeSeries | PointTimeSeries {
  if (type === "number") return data;

  return data.map(([timestamp, value, options]) => {
    let fakeAttrs: Partial<Highcharts.PointOptionsObject> = {};
    if (options?.fake) {
      fakeAttrs = {
        events: {
          mouseOver() {
            return false;
          }
        },
        marker: {
          states: {
            hover: {
              enabled: false
            }
          }
        }
      };
    }

    const point: Highcharts.PointOptionsObject = {
      x: timestamp,
      y: value as number | null,
      ...fakeAttrs
    };
    return point;
  });
}

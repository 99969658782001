import { mergeAssetConfig } from "@/config/asset-config-reader";
import baseVentilatorConfig from "./base-ventilator";
import diagram from "@/vs/components/diagrams/SqVentilator.vue";

const AIRFLOW_RULES = {
  fieldConfig: {
    rules: {
      between: { min: 120, max: 590 }
    }
  }
};

const config = mergeAssetConfig(baseVentilatorConfig, {
  diagramComponent: diagram,
  properties: {
    operatingMode: {
      dataType: "string",
      options: ["CAV", "DCV"],
      fieldConfig: {
        displayType: "select"
      }
    },
    target_airflow: AIRFLOW_RULES,
    zones_min_flow: AIRFLOW_RULES,
    zones_max_flow: AIRFLOW_RULES,
    schedule_airflow: AIRFLOW_RULES
  }
});

export default config;

import { mergeAssetConfig } from "@/config/asset-config-reader";
import baseDeviceConfig from "@/config/base-device";
import { getValue } from "@/config/form";
import { Form, RangeFn } from "@/types";

const TARGET_TEMP_LIMITS: Record<"AUTO" | "COOL_DRY" | "HEAT", { min: number; max: number }> = {
  AUTO: { min: 64.4, max: 86 },
  COOL_DRY: { min: 64.4, max: 86 },
  HEAT: { min: 61.7, max: 86 }
};

const targetTempLimits: RangeFn = ({ model }) => {
  const mode = getValue(model.config, model, "iu_mode_status") || "AUTO";

  switch (mode) {
    case "AUTO":
      return lowerUpperTempLimits(model, "iu_auto_temp_lower_limit", "iu_auto_temp_upper_limit");
    case "COOL":
    case "DRY":
      return lowerUpperTempLimits(model, "iu_cooldry_temp_lower_limit", "iu_cooldry_temp_upper_limit");
    default:
      return lowerUpperTempLimits(model, "iu_heat_temp_lower_limit", "iu_heat_temp_upper_limit");
  }
};

const lowerUpperTempLimits = (
  model: Form,
  lowerLimitProperty: string,
  upperLimitProperty: string
): { min: number; max: number } => {
  return {
    min: getValue(model.config, model, lowerLimitProperty),
    max: getValue(model.config, model, upperLimitProperty)
  };
};

const config = mergeAssetConfig(baseDeviceConfig, {
  i18nNamespace: "vrf",
  image: require("@/vrf/images/indoor-unit.svg"),
  components: {
    AssetDashboardTab: () => import("@/vrf/components/IndoorUnitDashboardTab.vue"),
    AssetControlPanel: () => import("@/vrf/components/IndoorUnit.vue"),
    AssetConfigTab: () => import("@/vrf/components/IndoorUnitConfigTab.vue"),
    AssetDialogProperties: {
      component: () => import("@/components/AssetDialogProperties.vue"),
      props: {
        itemProperties: ["iu_indoor_temp_status"]
      }
    }
  },
  // Temporarily prevent VRF fields from being displayed as stale under normal
  // operational circumstances (Jonah's request)
  staleDataDuration: 99999,
  categoryProperties: {
    temperature: { property: "iu_indoor_temp_status" }
  },
  properties: {
    iu_indoor_temp_status: {
      unit: "degrees_c",
      unitSelectorFn: () => "degrees_f",
      comparable: true
    },
    iu_unit_addr: {},
    iu_set_temp_status: {
      dataType: "number",
      category: "settings",
      dependsOnFields: [
        "iu_mode_status",
        "iu_auto_temp_lower_limit",
        "iu_auto_temp_upper_limit",
        "iu_cooldry_temp_lower_limit",
        "iu_cooldry_temp_upper_limit",
        "iu_heat_temp_lower_limit",
        "iu_heat_temp_upper_limit"
      ],
      format: "integer",
      unit: "degrees_c",
      unitSelectorFn: () => "degrees_f",
      fieldConfig: {
        rules: model => ({
          required: true,
          between: targetTempLimits(model),
          integer: true
        })
      }
    },
    iu_vert_air_direction_status: {
      dataType: "string",
      category: "settings",
      options: ["SWING", "POS1", "POS2", "POS3", "POS4"],
      fieldConfig: {
        displayType: "select",
        rules: { required: true }
      }
    },
    iu_horiz_air_direction_status: {
      dataType: "string",
      category: "settings",
      options: ["SWING", "POS1", "POS2", "POS3", "POS4", "POS5"],
      fieldConfig: {
        displayType: "select",
        rules: { required: true }
      }
    },
    iu_airflow_status: {
      dataType: "string",
      category: "settings",
      dependsOnFields: ["iu_mode_status"],
      options: ["AUTO", "LOW", "MED", "HIGH"],
      fieldConfig: {
        displayType: "select",
        disabled: ({ model }) => getValue(model.config, model, "iu_mode_status") === "DRY",
        rules: { required: true }
      }
    },
    iu_on_off_status: {
      dataType: "boolean",
      category: "settings"
    },
    iu_econ_status: {
      dataType: "boolean",
      category: "settings"
    },
    iu_antifreeze_status: {
      dataType: "boolean",
      category: "settings"
    },
    iu_mode_status: {
      dataType: "string",
      category: "settings",
      options: ["AUTO", "COOL", "DRY", "HEAT"],
      fieldConfig: {
        displayType: "select",
        rules: { required: true }
      }
    },
    iu_auto_temp_lower_limit: {
      dataType: "number",
      category: "settings",
      format: "integer",
      unit: "degrees_c",
      unitSelectorFn: () => "degrees_f",
      fieldConfig: {
        rules: {
          required: true,
          between: TARGET_TEMP_LIMITS["AUTO"],
          integer: true
        }
      }
    },
    iu_auto_temp_upper_limit: {
      dataType: "number",
      category: "settings",
      format: "integer",
      unit: "degrees_c",
      unitSelectorFn: () => "degrees_f",
      fieldConfig: {
        rules: {
          required: true,
          between: TARGET_TEMP_LIMITS["AUTO"],
          greater_rel: "@vrf.iu_auto_temp_lower_limit",
          integer: true
        }
      }
    },
    iu_cooldry_temp_lower_limit: {
      dataType: "number",
      category: "settings",
      format: "integer",
      unit: "degrees_c",
      unitSelectorFn: () => "degrees_f",
      fieldConfig: {
        rules: {
          required: true,
          between: TARGET_TEMP_LIMITS["COOL_DRY"],
          integer: true
        }
      }
    },
    iu_cooldry_temp_upper_limit: {
      dataType: "number",
      category: "settings",
      format: "integer",
      unit: "degrees_c",
      unitSelectorFn: () => "degrees_f",
      fieldConfig: {
        rules: {
          required: true,
          between: TARGET_TEMP_LIMITS["COOL_DRY"],
          greater_rel: "@vrf.iu_cooldry_temp_lower_limit",
          integer: true
        }
      }
    },
    iu_heat_temp_lower_limit: {
      dataType: "number",
      category: "settings",
      format: "integer",
      unit: "degrees_c",
      unitSelectorFn: () => "degrees_f",
      fieldConfig: {
        rules: {
          required: true,
          between: TARGET_TEMP_LIMITS["HEAT"],
          integer: true
        }
      }
    },
    iu_heat_temp_upper_limit: {
      dataType: "number",
      category: "settings",
      format: "integer",
      unit: "degrees_c",
      unitSelectorFn: () => "degrees_f",
      fieldConfig: {
        rules: {
          required: true,
          between: TARGET_TEMP_LIMITS["HEAT"],
          greater_rel: "@vrf.iu_heat_temp_lower_limit",
          integer: true
        }
      }
    },
    iu_rc_prevent_all: {
      dataType: "boolean",
      category: "settings"
    },
    iu_rc_prevent_timer_set: {
      dataType: "boolean",
      category: "settings"
    },
    iu_rc_prevent_room_temp_set: {
      dataType: "boolean",
      category: "settings"
    },
    iu_rc_prevent_op_mode_set: {
      dataType: "boolean",
      category: "settings"
    },
    iu_rc_prevent_start_stop_set: {
      dataType: "boolean",
      category: "settings"
    },
    iu_rc_prevent_start_set: {
      dataType: "boolean",
      category: "settings"
    },
    iu_rc_prevent_filter_reset: {
      dataType: "boolean",
      category: "settings"
    }
  }
});

export default config;

import { fromPairs, isNil } from "lodash";
import { Duration } from "luxon";
import { mdiCheckCircle, mdiAlert, mdiAlertCircle, mdiRecordCircle, mdiMinusCircle } from "@mdi/js";
import { getValue } from "@/config/form";
import { DecoratedAsset, Floor, Form, MetricIndicatorType, ThresholdPair, GqlGroup, Thresholds } from "@/types";
import i18n from "@/plugins/i18n";
import { DEFAULT_STALE_DATA_DURATION } from "@/config/constants";

export function deviceName(device: Form): string {
  const name = getValue(device.config, device, "descriptiveName");
  if (name?.match(/\S+/)) return name;
  return device.modelNumber?.match(/\S+/) ? device.modelNumber : "";
}

export function propertyName(
  asset: DecoratedAsset,
  propertyName: string,
  labelKey: string | null = null,
  labelValues: Record<string, any> = {}
): string {
  let key: string;

  if (isNil(labelKey)) {
    const namespace = asset.config.i18nNamespace;
    key = `properties.${namespace}.${propertyName}.label`;
  } else {
    key = labelKey;
  }

  return i18n.t(key, labelValues).toString();
}

export function floorName(floor: Floor): string {
  return floor.name;
}

export function geocodedBuildingCount(groups: GqlGroup[]): number {
  return groups.reduce((total, group) => {
    const buildingsWithLocs = group.buildings.filter(
      b => !isNil(b.gpsCoordinates?.lat) && !isNil(b.gpsCoordinates?.lng)
    );
    return total + buildingsWithLocs.length;
  }, 0);
}

export function buildingCount(groups: GqlGroup[]): number {
  return groups.reduce((total, group) => {
    return total + group.buildings.length;
  }, 0);
}

const THRESHOLD_MAPPING: Record<string, number> = {
  lowCritical: -2,
  lowWarning: -1,
  highWarning: 1,
  highCritical: 2
};

export function convertThresholdsToPairs(thresholdObject: Thresholds): ThresholdPair[] {
  const thresholds: ThresholdPair[] = [];

  for (const [thresholdName, value] of Object.entries(thresholdObject)) {
    if (!isNil(value)) {
      const violationLevel = THRESHOLD_MAPPING[thresholdName];
      if (!isNil(violationLevel)) thresholds.push({ violationLevel, compareValue: value });
    }
  }

  return thresholds;
}

export function convertPairsToThresholds(thresholds: ThresholdPair[]): Thresholds {
  const pairs = thresholds.map(t => [t.violationLevel, t.compareValue]);
  const levelValues = fromPairs(pairs);

  return {
    lowCritical: levelValues[-2] ?? null,
    lowWarning: levelValues[-1] ?? null,
    highWarning: levelValues[1] ?? null,
    highCritical: levelValues[2] ?? null
  };
}

export function determinePropertyStatus(
  value: number | null,
  thresholds: ThresholdPair[],
  relativeTimestamp: Duration | null = null,
  staleDataDuration: number | null = null
): MetricIndicatorType {
  if (isNil(value)) return MetricIndicatorType.Stale;

  if (relativeTimestamp) {
    const staleDuration = staleDataDuration ?? DEFAULT_STALE_DATA_DURATION;
    if (Math.abs(relativeTimestamp.as("minutes")) >= staleDuration) return MetricIndicatorType.Stale;
  }

  if (thresholds.length === 0) return MetricIndicatorType.Plain;

  const mappedThresholds = fromPairs(thresholds.map(t => [t.violationLevel.toString(), t.compareValue]));
  if (!isNil(mappedThresholds["-2"]) && value <= mappedThresholds["-2"]) return MetricIndicatorType.Critical;
  if (!isNil(mappedThresholds["-1"]) && value <= mappedThresholds["-1"]) return MetricIndicatorType.Warning;
  if (!isNil(mappedThresholds["2"]) && value >= mappedThresholds["2"]) return MetricIndicatorType.Critical;
  if (!isNil(mappedThresholds["1"]) && value >= mappedThresholds["1"]) return MetricIndicatorType.Warning;

  return MetricIndicatorType.Nominal;
}

const INDICATOR_STYLES: Record<MetricIndicatorType, Record<string, string>> = {
  [MetricIndicatorType.Nominal]: { color: "green", class: "text--darken-1", icon: mdiCheckCircle },
  [MetricIndicatorType.Warning]: { color: "yellow", class: "text--darken-2", icon: mdiAlert },
  [MetricIndicatorType.Critical]: { color: "red", class: "text--darken-1", icon: mdiAlertCircle },
  [MetricIndicatorType.Plain]: { color: "black", class: "text--darken-1", icon: mdiRecordCircle },
  [MetricIndicatorType.Stale]: { color: "grey", icon: mdiMinusCircle }
};

export function indicatorColor(indicatorType: MetricIndicatorType): string {
  return INDICATOR_STYLES[indicatorType].color;
}

export function indicatorTextClass(indicatorType: MetricIndicatorType): string {
  const style = INDICATOR_STYLES[indicatorType];
  return `${style.color}--text ${style.class}`;
}

export function indicatorIcon(indicatorType: MetricIndicatorType): string {
  return INDICATOR_STYLES[indicatorType].icon;
}

export function indicatorIconClass(indicatorType: MetricIndicatorType): string {
  return INDICATOR_STYLES[indicatorType].class;
}
